import ContentCopy from '@icons/content_copy.svg'
import Delete from '@icons/delete.svg'
import DoubleArrow from '@icons/double_arrow.svg'
import Edit from '@icons/edit.svg'
import ArrowDown from '@icons/keyboard_arrow_down.svg'
import ArrowRight from '@icons/keyboard_arrow_right.svg'
import Preview from '@icons/preview.svg'
import TabMove from '@icons/tab_move.svg'
import {
  Column,
  ColumnDef,
  ColumnFiltersState,
  ColumnOrderState,
  ColumnSizingState,
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  Row,
  RowData,
  RowSelectionState,
  SortingState,
  Table,
  useReactTable,
  VisibilityState,
  ExpandedState,
  getExpandedRowModel,
} from '@tanstack/react-table'
import { merge } from 'lodash'
import { FC, SVGProps, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import CheckBox from 'src/components/switchHoc/CheckBox'
import { UserDefinedFieldFilter } from 'src/fdvu/store/SystemFilterStore'
import { useTableKeeper } from 'src/query/tableConfig'
import { useDebouncedEffect } from 'src/utility/Debouncer'
import { capFirstLetter, classNames } from 'src/utility/utils'
import { ISingleFilter } from '../list/ListContextProvider'
import { filterType, IListColumns } from '../list/ListTypes'
import TableColumn from './TableColumn'

declare module '@tanstack/table-core' {
  interface TableMeta<TData extends RowData> {
    onPreviewClick?: (row: Row<TData>) => void
    onDeleteClick?: (row: Row<TData>) => void
    onCopyClick?: (row: Row<TData>) => void
    onRedirectClick?: (row: Row<TData>) => void
    onRowClick: (row: Row<TData>) => void
    getFilterOptions: (column: Column<TData>) => Promise<ISingleFilter[]>
    highlightedRows?: number[]
  }
  // eslint-disable-next-line
  // @ts-ignore
  // eslint-disable-next-line
  interface ColumnMeta<TData extends RowData> {
    column?: IListColumns
    filterType?: filterType
    name: string
    field: string // Backend mapping for accessing presentable data
    processFilter?: (
      filterVal: any,
    ) => Record<string, string | number | number[] | string[]>
    disabled?: boolean
    getFilter?: () => Promise<ISingleFilter[]>
  }
}

export type DateRangeFilterType = {
  start: string
  end: string
}

export type ColumnFilterValue = (string | boolean)[]

export type Sorting = {
  active: boolean
  direction: boolean
  field: string
  is_user_defined_field: boolean
}

export type TableFilter = {
  [key: Exclude<string, 'sort' | 'user_defined_values'>]:
    | string
    | number
    | string[]
    | number[]
    | Sorting
    | undefined
    | boolean
    | boolean[]
    | UserDefinedFieldFilter[]
  user_defined_values?: UserDefinedFieldFilter[]
  sort?: Sorting
}

export interface NonColumnFilters {
  user_defined_values?: UserDefinedFieldFilter[]
  [key: Exclude<string, 'user_defined_values'>]:
    | string
    | number
    | string[]
    | number[]
    | Sorting
    | undefined
    | boolean
    | boolean[]
    | UserDefinedFieldFilter[]
}

const isUserDefined = (id: string) => {
  return id.startsWith('meta_data')
}

const pickAccessorProperties = <T extends Record<string, unknown>>(
  key: string,
  data: T,
): T[keyof T] | Partial<T> => {
  const splitField: string[] = key.split('||')

  if (splitField.length > 1) {
    const fieldObj: Partial<T> = {}
    splitField.forEach((ssf) => {
      fieldObj[ssf as keyof T] = data[ssf as keyof T]
    })
    return fieldObj
  }

  return data[key as keyof T]
}

export const styleClass = {
  actionButton: (color?: string) =>
    classNames(
      'min-h-4 min-w-4',
      'hover:opacity-50',
      color ?? 'fill-blue-root',
    ),
}

const RowActionButtons = <T extends RowData>({
  row,
  table,
  DeleteIcon,
  deleteIconClassName,
  rowPreview = true,
  selectable = true,
}: {
  row: Row<T>
  table: Table<T>
  DeleteIcon?: FC<SVGProps<SVGElement>>
  deleteIconClassName?: string
  rowPreview?: boolean
  selectable?: boolean
}) => {
  const isRowSelectable = row.getCanSelect() && selectable
  const checkboxValue = row.getIsSelected()
  const onEditClick = false
  const onPreviewClick = table.options.meta?.onPreviewClick
  const onDeleteClick = table.options.meta?.onDeleteClick
  const onCopyClick = table.options.meta?.onCopyClick
  const onRedirectClick = table.options.meta?.onRedirectClick
  const highlightedRows = table.options.meta?.highlightedRows

  return (
    <div
      className="px-1 gap-2 w-full flex flex-row items-center overflow-hidden"
      onClick={(e) => e.stopPropagation()}
    >
      {isRowSelectable && (
        <CheckBox
          valueProp={checkboxValue}
          onChange={row.getToggleSelectedHandler()}
        />
      )}
      {row.getCanExpand() ? (
        row.getIsExpanded() ? (
          <ArrowDown
            onClick={row.getToggleExpandedHandler()}
            className={styleClass.actionButton('fill-gray-700')}
          />
        ) : (
          <ArrowRight
            onClick={row.getToggleExpandedHandler()}
            className={styleClass.actionButton('fill-gray-700')}
          />
        )
      ) : null}
      {onEditClick && (
        <Edit onClick={(e) => e} className={styleClass.actionButton()} />
      )}
      {onPreviewClick && rowPreview && (
        <Preview
          onClick={() => onPreviewClick(row)}
          className={styleClass.actionButton()}
        />
      )}
      {onCopyClick && (
        <ContentCopy
          onClick={() => onCopyClick(row)}
          className={styleClass.actionButton()}
        />
      )}
      {onRedirectClick && (
        <TabMove
          onClick={() => onRedirectClick(row)}
          className={styleClass.actionButton()}
        />
      )}
      {onDeleteClick &&
        (DeleteIcon ? (
          <DeleteIcon
            onClick={() => onDeleteClick(row)}
            className={styleClass.actionButton(
              twMerge(
                deleteIconClassName,
                !deleteIconClassName && 'fill-red-danger',
              ),
            )}
          />
        ) : (
          <Delete
            onClick={() => onDeleteClick(row)}
            className={styleClass.actionButton('fill-red-danger')}
          />
        ))}
      {highlightedRows && highlightedRows.includes(Number(row.id)) && (
        <div className="rounded-full bg-blue-root w-2 h-2" />
      )}
    </div>
  )
}

export type TableKeeperState = {
  pagination?: PaginationState
  sorting?: SortingState
  columnOrdering?: ColumnOrderState
  filters?: ColumnFiltersState
  columnSizing?: ColumnSizingState
  columnVisibility?: VisibilityState
  nonColumnFilters?: Omit<TableFilter, 'sort'>
}

export type SavedTableConfig = {
  name: string
  config: TableKeeperState
  userId: number
  public: boolean
  projectId: number
  modelName: string
}

export type UseTableProps<T> = {
  name: string
  data: T[]
  legacyColumns?: IListColumns[]
  columns: ColumnDef<T>[]
  onPreviewClick?: (row: T) => void
  onDeleteClick?: (row: T) => void
  onCopyClick?: (row: T) => void
  onRedirectClick?: (row: T) => void
  onRowClick?: (item: T) => void
  defaultOrdering?: string[]
  onSelect?: (state: RowSelectionState) => void
  selectionState?: RowSelectionState
  highlightedRows?: number[]
  selectable?: boolean
  deleteIcon?: FC<SVGProps<SVGElement>>
  deleteIconClassName?: string
  enableFilters?: boolean
  enableSorting?: boolean
  initialColumnVisibility?: VisibilityState
  defaultFilter?: ColumnFiltersState
  disableTableKeeper?: boolean
  frontendState?: boolean
}

const useTable = <T extends { id: string | number; subRows?: T[] }>({
  name,
  legacyColumns,
  columns,
  data,
  onPreviewClick,
  onDeleteClick,
  onCopyClick,
  onRedirectClick,
  onRowClick,
  defaultOrdering = [],
  onSelect,
  selectionState,
  highlightedRows,
  deleteIcon,
  deleteIconClassName,
  selectable = true,
  enableFilters = true,
  enableSorting = true,
  initialColumnVisibility,
  defaultFilter,
  disableTableKeeper,
  frontendState = false,
}: UseTableProps<T>) => {
  const columnHelper = createColumnHelper<T>()
  const { t } = useTranslation()

  const getTranslatedColumnName = (val: string) => {
    const hasParam = val.match(',')

    if (hasParam) {
      const splittedVal = val.split(',')
      return capFirstLetter(
        t(splittedVal[0], { [splittedVal[1]]: splittedVal[2] }),
      )
    } else {
      return capFirstLetter(t(val))
    }
  }

  const columnMigrator = (columns: IListColumns[]) =>
    columns.map(
      (col): ColumnDef<T> => ({
        header: (props) => (
          <TableColumn column={props.column} table={props.table} />
        ),
        accessorFn: (dataRow) => pickAccessorProperties(col.dataField, dataRow),
        id: col.id,
        enableColumnFilter: col.enableColumnFilter,
        enableSorting: col.enableSorting,
        cell: (props) => (
          <>
            {col.cell
              ? col.cell(props.cell.getValue(), +props.row.id, props.row.index)
              : (props.cell.getValue() as JSX.Element)}
          </>
        ),
        size: Number(col.size),
        meta: {
          name: getTranslatedColumnName(col.name),
          field: col.filterDataField ?? col.dataField,
          column: col,
          filterType: col.filterType,
          disabled: col.disabled,
        },
      }),
    )

  const defaultColumns = columnMigrator(legacyColumns ?? [])

  const selectColumnMinSize = 25
  const sizePerButton = 25
  const selectColumnMaxSize = useMemo(() => {
    const actionButtons = [
      onDeleteClick,
      onCopyClick,
      onRedirectClick,
      highlightedRows,
    ].filter((action) => action !== undefined)
    const totalNr =
      actionButtons.length + (selectable ? 1 : 0) + (onPreviewClick ? 1 : 0)

    return totalNr * sizePerButton
  }, [
    onDeleteClick,
    onCopyClick,
    onRedirectClick,
    highlightedRows,
    onRowClick,
    onPreviewClick,
  ])

  const selectColumn = columnHelper.display({
    id: 'select',
    cell: (props) => (
      <RowActionButtons
        row={props.row}
        table={props.table}
        DeleteIcon={deleteIcon}
        deleteIconClassName={deleteIconClassName}
        rowPreview={!!onPreviewClick}
        selectable={selectable}
      />
    ),
    header: (props) => (
      <div className="px-1 flex justify-between items-center h-full">
        {selectable && (
          <CheckBox
            valueProp={props.table.getIsAllRowsSelected()}
            onChange={(val) => props.table.toggleAllRowsSelected(val)}
          />
        )}
        <DoubleArrow
          className={twMerge(
            'hover:fill-blue-root hover:opacity-100',
            'opacity-30 text-lg',
            'cursor-pointer',
            props.header.getSize() === selectColumnMaxSize && 'invisible',
          )}
          onClick={props.column.resetSize}
        />
      </div>
    ),
    minSize: selectColumnMinSize,
    maxSize: selectColumnMaxSize,
  })

  const haveActionButtons =
    onPreviewClick ||
    selectable ||
    onCopyClick ||
    onRedirectClick ||
    onDeleteClick ||
    highlightedRows

  const columnDefs = useMemo(
    () => [
      ...(haveActionButtons ? [selectColumn] : []),
      ...defaultColumns,
      ...columns.filter((col) => !col.meta?.disabled),
    ],
    [columns],
  )

  const getFilterOptions = async (column: Column<T>) => {
    if (column.columnDef.meta?.getFilter) {
      return (await column.columnDef.meta.getFilter()) ?? []
    } else if (column.columnDef.meta?.column?.getFilter) {
      return (await column.columnDef.meta.column.getFilter()) ?? []
    }
    return column.columnDef.meta?.column?.filter ?? []
  }
  const onPreviewClickHandler = onPreviewClick
    ? (row: Row<T>) => onPreviewClick?.(row.original)
    : undefined
  const onDeleteClickHandler = onDeleteClick
    ? (row: Row<T>) => onDeleteClick?.(row.original)
    : undefined
  const onCopyClickHandler = onCopyClick
    ? (row: Row<T>) => onCopyClick?.(row.original)
    : undefined
  const onRedirectClickHandler = onRedirectClick
    ? (row: Row<T>) => onRedirectClick?.(row.original)
    : undefined
  const onRowClickHandler = (row: Row<T>) => {
    onRowClick?.(row.original)
  }

  const {
    data: tableConfig,
    isPending,
    mutate: mutateSessionConfig,
  } = useTableKeeper<TableKeeperState>(name)

  const [pagination, setPagination] = useState<PaginationState>({
    pageSize: tableConfig?.pagination?.pageSize ?? 30,
    pageIndex: tableConfig?.pagination?.pageIndex ?? (frontendState ? 0 : 1),
  })
  const defaultOrder = [
    ...defaultOrdering,
    ...columnDefs
      .map((c) => c.id as string)
      .filter((id) => !defaultOrdering.includes(id)),
  ]
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(
    !!tableConfig?.columnOrdering?.length
      ? tableConfig.columnOrdering
      : defaultOrder,
  )
  const [columnSizing, setColumnSizing] = useState<ColumnSizingState>(
    tableConfig?.columnSizing ?? {},
  )
  const [sorting, setSorting] = useState<SortingState>(
    tableConfig?.sorting ?? [],
  )
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [nonColumnFilters, setNonColumnFilters] = useState<NonColumnFilters>({})
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    ...(initialColumnVisibility ?? {}),
    ...(tableConfig?.columnVisibility ?? {}),
  })
  const [expanded, setExpanded] = useState<ExpandedState>({})

  const [configFetched, setConfigFetched] = useState(false)

  const initSorting = useCallback(
    (sorting: SortingState) => {
      const validColumns = sorting.filter((j) =>
        columnDefs.map((i) => i.id).includes(j.id),
      )
      setSorting(validColumns)
    },
    [columnDefs],
  )

  const initFiltering = useCallback(
    (filters: ColumnFiltersState) => {
      const validColumns = filters.filter((j) =>
        columnDefs.map((i) => i.id).includes(j.id),
      )
      setColumnFilters(validColumns)
    },
    [columnDefs],
  )

  useEffect(() => {
    setColumnFilters((defaultFilter || tableConfig?.filters) ?? [])
  }, [defaultFilter])

  useEffect(() => {
    setColumnVisibility(
      (initialColumnVisibility || tableConfig?.columnVisibility) ?? {},
    )
  }, [initialColumnVisibility])

  useEffect(() => {
    if (!isPending && tableConfig && !disableTableKeeper) {
      if (tableConfig.pagination) setPagination(tableConfig.pagination)
      if (tableConfig.filters) initFiltering(tableConfig.filters)
      if (tableConfig.sorting) initSorting(tableConfig.sorting)
      if (tableConfig.columnOrdering) {
        const missingColumns = columnDefs
          .map((c) => c.id as string)
          .filter((id) => !tableConfig.columnOrdering?.includes(id))
        setColumnOrder([...tableConfig.columnOrdering, ...missingColumns])
      }
      if (tableConfig.columnVisibility)
        setColumnVisibility(tableConfig.columnVisibility)
      if (tableConfig.columnSizing) setColumnSizing(tableConfig.columnSizing)
      if (tableConfig.nonColumnFilters)
        setNonColumnFilters(tableConfig.nonColumnFilters)
    }
    if (!isPending && !configFetched) {
      setConfigFetched(true)
    }
  }, [tableConfig, isPending])

  useDebouncedEffect(
    () => {
      mutateSessionConfig({
        sorting,
        columnOrdering: columnOrder,
        filters: columnFilters,
        pagination,
        columnSizing,
        columnVisibility,
        nonColumnFilters,
      })
    },
    500,
    [
      mutateSessionConfig,
      sorting,
      columnOrder,
      columnSizing,
      columnFilters,
      pagination,
      columnVisibility,
      nonColumnFilters,
    ],
  )

  const onColumnFiltersChange = (state: ColumnFiltersState) => {
    setColumnFilters(state)
    // eslint-disable-next-line no-use-before-define, @typescript-eslint/no-use-before-define
    table.setPageIndex(1)
  }

  const optionalState = selectionState ? { rowSelection: selectionState } : {}

  const simpleTableOptions = {
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  }

  const table = useReactTable({
    columns: columnDefs.filter((col) => !col.meta?.disabled),
    data,
    defaultColumn: {
      minSize: 40,
      maxSize: 800,
    },
    // Fix for odd bug where the sorting skipped desc sorting for certain columns, source: https://github.com/TanStack/table/issues/4289
    sortDescFirst: true,
    getRowId: (row) => String(row.id),
    state: {
      columnOrder: !!columnOrder.length ? columnOrder : defaultOrder,
      columnSizing,
      pagination,
      sorting,
      columnFilters,
      columnVisibility,
      expanded,
      ...optionalState,
    },
    meta: {
      getFilterOptions,
      onPreviewClick: onPreviewClickHandler,
      onDeleteClick: onDeleteClickHandler,
      onCopyClick: onCopyClickHandler,
      onRedirectClick: onRedirectClickHandler,
      onRowClick: onRowClickHandler,
      highlightedRows,
    },
    enableMultiSort: false,
    onPaginationChange: setPagination,
    manualPagination: !frontendState,
    onColumnOrderChange: setColumnOrder,
    onColumnSizingChange: setColumnSizing,
    columnResizeMode: 'onChange',
    onSortingChange: setSorting,
    onColumnFiltersChange: onColumnFiltersChange,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: onSelect,
    enableRowSelection: selectable,
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
    // Expanding table docs: tanstack.com/table/v8/docs/framework/react/examples/expanding
    // Lazy loading example: medium.com/tenjin-engineering/lazy-loading-expandable-rows-with-react-table-cd2fc86b0630
    getSubRows: (row: T) => row.subRows,
    enableSorting,
    enableFilters,
    ...(frontendState && simpleTableOptions),
  })

  // Clear filters with empty values
  useEffect(() => {
    if (
      columnFilters.some(
        (filter) =>
          (filter.value as ColumnFilterValue | undefined)?.length === 0,
      )
    ) {
      setColumnFilters((filters) =>
        filters?.filter(
          (v) => (v.value as ColumnFilterValue | undefined)?.length !== 0,
        ),
      )
    }
  }, [columnFilters])

  const keyedFilters = useMemo(() => {
    const filters =
      columnFilters?.reduce(
        (allFilters, f) => ({
          ...allFilters,
          [f.id]: f.value as string | number | string[] | number[],
        }),
        {} as Record<string, string | number | number[] | string[]>,
      ) ?? {}

    const cols = table.getAllLeafColumns()
    let processedFilters = {}

    for (const col of cols) {
      const processor =
        col.columnDef.meta?.processFilter ??
        col.columnDef.meta?.column?.processFilter
      if (filters[col.id] && processor) {
        const filter = isUserDefined(col.id)
          ? {
              user_defined_values: [
                ...(processedFilters['user_defined_values'] ?? []),
                processor(filters[col.id]),
              ],
            }
          : processor(filters[col.id])
        processedFilters = merge(processedFilters, filter)
      } else {
        processedFilters[col.id] = filters[col.id]
      }
    }

    return processedFilters
  }, [columnFilters, table])

  const parsedSorting = useMemo(() => {
    const fieldId = sorting?.[0]?.id ?? ''
    return {
      field: isUserDefined(fieldId) ? fieldId.split('_')[2] : fieldId,
      direction: sorting?.[0] ? !sorting[0].desc : false,
      active: (sorting?.length ?? 0) > 0,
      is_user_defined_field: isUserDefined(fieldId),
    }
  }, [sorting])

  return {
    table,
    pageSize: pagination?.pageSize,
    page: pagination?.pageIndex,
    filters: keyedFilters,
    sorting: parsedSorting,
    tableConfigPending: !configFetched,
    setPagination,
    nonColumnFilters,
    setNonColumnFilters,
  }
}

export default useTable
